<script>
import { Bar } from "vue-chartjs";
import StatisticsService from '@/services/statistics';
import formatDuration from '@/utils/format-duration';

export default {
  extends: Bar,
	props: {
		updateCount: {
			type: Number,
			required: false,
		}
	},
	data() {
		return {
			chartData: null,
		}
	},
	computed: {
		userInfo() {
			return this.$store.state.user.userInfo;
		},
		chartLabels() {
			return this.chartData.map(val => this.getDayChartLabel(val.date));
		},
		chartNumbers() {
			return this.chartData.map(val => val.duration);
		},
	},
	watch: {
		async userInfo(userInfo) {
			await StatisticsService.dailyTotals(userInfo.id)
				.then((result) => {
					this.chartData = result;
					this.makeChart();
				});
		},
		async updateCount() {
			await StatisticsService.dailyTotals(this.userInfo.id)
				.then((result) => {
					this.chartData = result;
					this.makeChart();
				});
		}
	},	
	methods: {
		makeChart() {
			this.renderChart(
				{
					labels: this.chartLabels,
					datasets: [
						{
							label: "Daily Total",
							backgroundColor: "#02a499",
							borderColor: "#02a499",
							borderWidth: 1,
							hoverBackgroundColor: "#02a499",
							hoverBorderColor: "#02a499",
							data: this.chartNumbers,
						}
					]
				},
				{
					responsive: true,
					tooltips: {
						enabled: true,
						callbacks: {
							label: function({ index }, data) {
								const duration = data.datasets[0].data[index];
								const formattedDuration = formatDuration(duration);
								return formattedDuration;
							},
						}
					},
					maintainAspectRatio: false,
					scales: {
						yAxes: [
							{
								gridLines: {
									display: true
								},
								stacked: false,
								ticks: {
									stepSize: 20
								}
							}
						],
						xAxes: [
							{
								stacked: false,
								gridLines: {
									color: "rgba(0,0,0,0.01)"
								}
							}
						]
					}
				}
			);
		},
		getDayChartLabel(date) {
			return this.$moment(date).format('ddd MMM D');
		}
	}
};
</script>