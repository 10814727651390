<template>
  <div />
</template>

<script>
import UsersService from '@/services/users';

export default {
	name: 'Tour',

	methods: {
		handleTourComplete() {
			UsersService.tourComplete(this.$store.state.user.userInfo.id);
		}
	},

	mounted() {
		this.$nextTick(() => {
			const tour = this.$shepherd({
				useModalOverlay: true
			});

			// welcome
			tour.addStep({
				text: 'Welcome to Turbo Time Tracker! \u{1F44B}',
				buttons: [{
					text: 'Next',
					action: tour.next,
				}],
			});

			// nav links
			tour.addStep({
				text: 'Click here to return to your home page from anywhere on the dashboard.',
				attachTo: {
					element: '#home-nav-link',
					on: 'bottom',
				},
				buttons: [
					{
						text: 'Previous',
						action: tour.back,
					},
					{
						text: 'Next',
						action: tour.next,
					},
				],
				canClickTarget: false,
			});
		
			tour.addStep({
				text: `This button takes you to the Reports page. 
					<br/><br/>This is where you can generate custom reports to analyse your activity.`
				,
				attachTo: {
					element: '#reports-nav-link',
					on: 'bottom',
				},
				buttons: [
					{
						text: 'Previous',
						action: tour.back,
					},
					{
						text: 'Next',
						action: tour.next,
					},
				],
				canClickTarget: false,
			});

			tour.addStep({
				text: `The projects page lets you create and manage projects.
					<br/><br/>You can create time entries without them but they help to keep your activity organised.`,
				attachTo: {
					element: '#projects-nav-link',
					on: 'bottom',
				},
				buttons: [
					{
						text: 'Previous',
						action: tour.back,
					},
					{
						text: 'Next',
						action: tour.next,
					},
				],
				canClickTarget: false,
			});

			tour.addStep({
				text: `Time Entries are the core of the Turbo Time Tracker system.
					<br/><br/>This page lets you create, edit, and view all your time entries in one place.`,
				attachTo: {
					element: '#time-entries-nav-link',
					on: 'bottom',
				},
				buttons: [
					{
						text: 'Previous',
						action: tour.back,
					},
					{
						text: 'Next',
						action: tour.next,
					},
				],
				canClickTarget: false,
			});

			tour.addStep({
				text: `Tags are used to provide extra detail to your time entries.
					<br/><br/>The tags page lets you create, edit, and view all of your tags.`,
				attachTo: {
					element: '#tags-nav-link',
					on: 'bottom',
				},
				buttons: [
					{
						text: 'Previous',
						action: tour.back,
					},
					{
						text: 'Next',
						action: tour.next,
					},
				],
				canClickTarget: false,
			});

			tour.addStep({
				text: 'You can manage your subscription and profile information via the settings page.',
				attachTo: {
					element: '#settings-nav-link',
					on: 'bottom',
				},
				buttons: [
					{
						text: 'Previous',
						action: tour.back,
					},
					{
						text: 'Next',
						action: tour.next,
					},
				],
				canClickTarget: false,
			});

			tour.addStep({
				text: 'You can always get in touch with us via the Support page.',
				attachTo: {
					element: '#support-nav-link',
					on: 'bottom',
				},
				buttons: [
					{
						text: 'Previous',
						action: tour.back,
					},
					{
						text: 'Next',
						action: tour.next,
					},
				],
				canClickTarget: false,
			});

			// top 10 projects
			tour.addStep({
				text: 'This chart shows you which projects have the most time recorded against them.',
				attachTo: {
					element: '#top-10-projects',
					on: 'top',
				},
				buttons: [
					{
						text: 'Previous',
						action: tour.back,
					},
					{
						text: 'Next',
						action: tour.next,
					},
				],
				classes: 'projects-tour-step',
				canClickTarget: false,
			});

			// recent activity
			tour.addStep({
				text: 'This chart provides a quick summary of time logged in the past week.',
				attachTo: {
					element: '#recent-activity',
					on: 'top',
				},
				buttons: [
					{
						text: 'Previous',
						action: tour.back,
					},
					{
						text: 'Next',
						action: tour.next,
					},
				],
				classes: 'recent-activity-tour-step',
				canClickTarget: false,
			});

			// New Time Entry			
			tour.addStep({
				text: 'This button allows you to create a Time Entry straight from the home page.',
				attachTo: {
					element: '#new-time-entry-button',
					on: 'top',
				},
				buttons: [
					{
						text: 'Previous',
						action: tour.back,
					},
					{
						text: 'Next',
						action: tour.next,
					},
				],
				classes: 'time-entry-button-tour-step',
				canClickTarget: false,
			});

			// finish
			tour.addStep({
				text: `Explore the other pages and create some time entries when you're ready to get started.
					<br/><br/> If you have any questions or feedback, please feel free to get in touch with us via email at: <a href="mailto:paul.maxwell@hey.com">paul.maxwell@hey.com</a>
					<br/><br/> Happy time tracking! &#x1F389;`,
				buttons: [{
					text: 'Finish',
					action: tour.complete,
				}],
			});

			tour.start();

			tour.on('complete', () => this.handleTourComplete());
		});
	}
};
</script>

<style lang="scss">
@import '~shepherd.js/dist/css/shepherd.css';

.projects-tour-step, .recent-activity-tour-step, .time-entry-button-tour-step {
	margin-bottom: 12px !important;
}
</style>