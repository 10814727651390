<template>
	<Layout>
		<PageHeader 
			:title="title"
			v-bind="actionButton"
			@openCreateTimeEntryModal="toggleCreateTimeEntryModal"
		/>
		<div class="row">
			<div class="col-lg-6">
				<div class="card">
					<div id="top-10-projects" class="card-body">
						<h4 class="card-title mb-4">
							Top 10 Projects
						</h4>
						<TopProjectsPieChart
							v-if="topProjectsChartData && topProjectsChartData.length"
							:chartData="topProjectsChartData"
							:height="300"
						/>
						<div v-else-if="topProjectsChartData">
							<h2 class="text-center empty-table">Nothing here yet!</h2>
							<p class="text-center text-muted">Try creating a project and some time entries to get started.</p>
						</div>
					</div>
				</div>
			</div>
			<!-- end col -->
			<div class="col-lg-6">
				<div class="card">
					<div 
						id="recent-activity"
						class="card-body"
					>
						<h4 class="card-title mb-4">
							Recent Activity
						</h4>
						<DailyActivityBarChart
							:height="300"
							:updateCount="updateCount"
						/>
					</div>
				</div>
			</div>
			<!-- end col -->
		</div>
		<b-modal
			id="create-time-entry-modal"
			ref="create-time-entry-modal"
			title="Create Time Entry"
			hide-header
			hide-footer
			no-close-on-backdrop
			centered
		>
			<CreateTimeEntry
				:showCancel="true"
				@cancelCreateTimeEntry="toggleCreateTimeEntryModal"
				@timeEntryCreated="timeEntryCreated"
			/>
		</b-modal>
		<Tour v-if="isTourComplete === false"/>
	</Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import DailyActivityBarChart from '../../../components/client-dashboard/daily-activity-bar-chart.vue';
import TopProjectsPieChart from '../../../components/client-dashboard/top-projects-pie-chart.vue';
import StatisticsService from '@/services/statistics';
import CreateTimeEntry from '@/components/client-dashboard/create-time-entry';
import Swal from "sweetalert2";
import Tour from '@/components/client-dashboard/tour';

export default {
	page: {
		title: "Home",
		meta: [{ name: "ClientDashboardHome", content: appConfig.description }]
	},
	components: { 
		Layout,
		PageHeader,
		DailyActivityBarChart,
		TopProjectsPieChart,
		CreateTimeEntry,
		Tour,
	},
	data() {
		return {
			title: "Home",
			topProjectsChartData: null,
			actionButton: {
				buttonId: 'new-time-entry-button',
				buttonText: 'New Time Entry',
				buttonEvent: 'openCreateTimeEntryModal',
				buttonClasses: 'btn-success',
				iconClasses: 'fas fa-plus',
				isDisabled: false,
				tooltip: null,
			},
			isTourComplete: null,
			updateCount: 0, // a hack to make the activity chart update itself
		};
	},
	computed: {
		userInfo() {
			return this.$store.state.user.userInfo;
		},
	},
	methods: {
		toggleCreateTimeEntryModal() {
			this.$refs['create-time-entry-modal'].toggle();
		},
		timeEntryCreated() {
			this.$bvModal.hide('create-time-entry-modal');
			Swal.fire({
				icon: "success",
				title: "Success!",
				html: `Time entry created.`,
				timer: 1000,
				showConfirmButton: false,
			});
			this.fetchStatistics();
			this.updateCount++;
		},
		fetchStatistics() {
			StatisticsService.topProjects(this.userInfo.id)
				.then((result) => {
					this.topProjectsChartData = result;
				});
		},
	},
	watch: {
		userInfo(userInfo) {
			this.isTourComplete = userInfo.isTourComplete;
			StatisticsService.topProjects(userInfo.id)
				.then((result) => {
					this.topProjectsChartData = result;
				});
		},
	},
	mounted() {
		this.$store.dispatch('user/refreshUserInfo');
	}
};
</script>

<style lang="scss" scoped>
.empty-table {
	margin: 50px 0 20px 0;
	color: #9ca8b3;
}
</style>